export const defaults = {
  credentials: "include",
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
};

export default function authorizedFetch(path, options = {}) {
  //  make a deep copy of defaults so we can modify default.headers without leaking changes
  const optionsWithDefaults = {
    ...structuredClone(defaults),
    ...options,
  };

  if (!optionsWithDefaults.headers["X-CSRF-Token"]) {
    if (process.env.STORYBOOK === "true") {
      const cookie = document.cookie.split(";").find((c) => c.trim().startsWith("CSRF-TOKEN="));
      if (cookie) {
        // eslint-disable-next-line prefer-destructuring
        optionsWithDefaults.headers["X-CSRF-Token"] = cookie.trim().split("=")[1];
      }
    } else {
      optionsWithDefaults.headers["X-CSRF-Token"] = document
        .querySelector("meta[name=csrf-token]")
        ?.getAttribute("content");
    }
  }

  if (!options.autoContentType) {
    optionsWithDefaults.headers["Content-Type"] = "application/json";
  }

  return fetch(path, optionsWithDefaults);
}

export const fetcher = async (url) => {
  const res = await authorizedFetch(url);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};
